import PropTypes from 'prop-types';
import React from 'react';
import styles from './line-indicator.module.scss';

const LineIndicator = ({ height, activeItem }) => (
  <div className={`${styles.lineIndicator} ${activeItem === 1 ? styles.showElements : ''}`}>
    <div
      className={`${styles.lineIndicator_item}`}
      style={{ height }}
    />
  </div>
);
LineIndicator.propTypes = {
  height: PropTypes.number.isRequired,
  activeItem: PropTypes.number.isRequired,
};

export default LineIndicator;
