/* eslint-disable no-empty */
import { useCallback, useRef } from 'react';
import { replaceSpace } from '../helpers/helpers';

export default function useGoToPage(location, isMobile, MENU_ITEMS, currentPage) {
  const ref = useRef(null);
  const setRef = useCallback((node) => {
    if (node) {
      // Check if a node is actually passed. Otherwise node would be null.
      // You can now do what you need to, addEventListeners, measure, etc.
      if (!isMobile) {
        const locationLink = location.hash.length ? +location.hash.replace('#', '') : 0;
        setTimeout(() => {
          node.goToPage(locationLink - 1);
        }, 100);
      } else {
        const name = MENU_ITEMS[currentPage - 2];
        if (name) {
          const section = document.querySelector(`#${replaceSpace(name)}`);
          if (section) {
            setTimeout(() => {
              try {
                document.querySelector(`#${replaceSpace(name)}`).scrollIntoView({ block: 'start', behavior: 'smooth' });
              } catch (error) {}
            }, 100);
          }
        }
      }
    }

    // Save a reference to the node
    ref.current = node;
  }, [isMobile]);
  const goToPage = (index, name) => {
    if (!isMobile) {
      ref.current.goToPage(index);
    } else {
      document.querySelector(`#${replaceSpace(name)}`).scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  };

  return [setRef, goToPage];
}
