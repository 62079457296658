import PropTypes from 'prop-types';
import React from 'react';

import styles from './count-box.module.scss';


const CountBox = ({ number, textArray, parentStyles }) => {
  const stys = { ...styles, ...parentStyles };

  return (
    <div className={`${stys.countBox}`}>
      <div className={`${stys.animatedText} ${stys.countBox_number}`}>{number}</div>
      {textArray.map((text) => (
        <div className={`${stys.animatedText} ${stys.countBox_text}`}>{text}</div>
      ))}
    </div>
  );
};
CountBox.propTypes = {
  number: PropTypes.string.isRequired,
  textArray: PropTypes.arrayOf(PropTypes.string).isRequired,
  parentStyles: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default CountBox;
