/* eslint-disable no-multi-assign */
/* eslint-disable func-names */
/* eslint-disable consistent-return */
/* eslint-disable symbol-description */
/* eslint-disable no-return-assign */
/* eslint-disable no-shadow */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-proto */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-use-before-define */
/* eslint-disable no-underscore-dangle */

exports.__esModule = true;
exports.default = undefined;

let _class; let
  _temp;

const _react = require('react');

const _react2 = _interopRequireDefault(_react);

require('babel-polyfill');

const _propTypes = require('prop-types');

const _propTypes2 = _interopRequireDefault(_propTypes);

const _lodash = require('lodash');

const _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError('Cannot call a class as a function'); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === 'object' || typeof call === 'function') ? call : self; }

function _inherits(subClass, superClass) {
  if (typeof superClass !== 'function' && superClass !== null) { throw new TypeError(`Super expression must either be null or a function, not ${typeof superClass}`); } subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass, enumerable: false, writable: true, configurable: true,
    },
  }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

const previousTouchMove = Symbol();
const scrolling = Symbol();
const wheelScroll = Symbol();
const touchMove = Symbol();
const keyPress = Symbol();
const onWindowResized = Symbol();
const addNextComponent = Symbol();
const scrollWindowUp = Symbol();
const scrollWindowDown = Symbol();
const setRenderComponents = Symbol();
const _isMounted = Symbol();

const _isBodyScrollEnabled = Symbol();
const disableScroll = Symbol();
const enableScroll = Symbol();

const ANIMATION_TIMER = 200;
const KEY_UP = 38;
const KEY_DOWN = 40;
const DISABLED_CLASS_NAME = 'rps-scroll--disabled';

const ReactPageScroller = (_temp = _class = (function (_React$Component) {
  _inherits(ReactPageScroller, _React$Component);

  function ReactPageScroller(props) {
    _classCallCheck(this, ReactPageScroller);

    const _this = _possibleConstructorReturn(this, _React$Component.call(this, props));

    _this.componentDidMount = function () {
      _this[_isMounted] = true;

      window.addEventListener('resize', _this[onWindowResized]);

      document.ontouchmove = function (event) {
        event.preventDefault();
      };

      _this._pageContainer2.addEventListener('wheel', _this[wheelScroll]);
      _this._pageContainer.addEventListener('touchmove', _this[touchMove]);
      _this._pageContainer.addEventListener('keydown', _this[keyPress]);

      let componentsToRenderLength = 0;

      if (!_lodash2.default.isNil(_this.props.children[_this.state.componentIndex])) {
        componentsToRenderLength++;
      } else {
        componentsToRenderLength++;
      }

      _this[addNextComponent](componentsToRenderLength);
    };

    _this.componentWillUnmount = function () {
      _this[_isMounted] = false;

      window.removeEventListener('resize', _this[onWindowResized]);

      document.ontouchmove = function () {
        return true;
      };

      _this._pageContainer2.removeEventListener('wheel', _this[wheelScroll]);
      _this._pageContainer.removeEventListener('touchmove', _this[touchMove]);
      _this._pageContainer.removeEventListener('keydown', _this[keyPress]);
    };

    _this.goToPage = function (number) {
      const _this$props = _this.props;
      const { pageOnChange } = _this$props;
      const { children } = _this$props;
      const _this$state = _this.state;
      const { componentIndex } = _this$state;
      const { componentsToRenderLength } = _this$state;


      let newComponentsToRenderLength = componentsToRenderLength;

      if (!_lodash2.default.isEqual(componentIndex, number)) {
        if (!_lodash2.default.isNil(_this[`container_${number}`]) && !_this[scrolling]) {
          _this[scrolling] = true;
          _this._pageContainer.style.transform = `translate3d(0, ${number * -100}%, 0)`;

          if (pageOnChange) {
            pageOnChange(number + 1);
          }

          if (_lodash2.default.isNil(_this[`container_${number + 1}`]) && !_lodash2.default.isNil(children[number + 1])) {
            newComponentsToRenderLength++;
          }

          setTimeout(() => {
            _this.setState({ componentIndex: number, componentsToRenderLength: newComponentsToRenderLength }, () => {
              _this[scrolling] = false;
              _this[previousTouchMove] = null;
            });
          }, _this.props.animationTimer + ANIMATION_TIMER);
        } else if (!_this[scrolling] && !_lodash2.default.isNil(children[number])) {
          for (let i = componentsToRenderLength; i <= number; i++) {
            newComponentsToRenderLength++;
          }

          if (!_lodash2.default.isNil(children[number + 1])) {
            newComponentsToRenderLength++;
          }

          _this[scrolling] = true;
          _this.setState({
            componentsToRenderLength: newComponentsToRenderLength,
          }, () => {
            _this._pageContainer.style.transform = `translate3d(0, ${number * -100}%, 0)`;

            if (pageOnChange) {
              pageOnChange(number + 1);
            }

            setTimeout(() => {
              _this.setState({ componentIndex: number }, () => {
                _this[scrolling] = false;
                _this[previousTouchMove] = null;
              });
            }, _this.props.animationTimer + ANIMATION_TIMER);
          });
        }
      }
    };

    _this[disableScroll] = function () {
      if (_this[_isBodyScrollEnabled]) {
        _this[_isBodyScrollEnabled] = false;
        window.scrollTo({
          left: 0,
          top: 0,
          behavior: 'smooth',
        });
        document.body.classList.add(DISABLED_CLASS_NAME);
        document.documentElement.classList.add(DISABLED_CLASS_NAME);
      }
    };

    _this[enableScroll] = function () {
      if (!_this[_isBodyScrollEnabled]) {
        _this[_isBodyScrollEnabled] = true;
        document.body.classList.remove(DISABLED_CLASS_NAME);
        document.documentElement.classList.remove(DISABLED_CLASS_NAME);
      }
    };

    _this[wheelScroll] = function (event) {
      if (!_this[scrolling]) {
        if (event.deltaY < 0) {
          _this[scrollWindowUp]();
        } else {
          _this[scrollWindowDown]();
        }
      } else {
        event.preventDefault();
      }
    };

    _this[touchMove] = function (event) {
      if (!_this[scrolling]) {
        if (!_lodash2.default.isNull(_this[previousTouchMove])) {
          if (event.touches[0].clientY > _this[previousTouchMove]) {
            _this[scrollWindowUp]();
          } else {
            _this[scrollWindowDown]();
          }
        } else {
          _this[previousTouchMove] = event.touches[0].clientY;
        }
      } else {
        event.preventDefault();
      }
    };

    _this[keyPress] = function (event) {
      if (_lodash2.default.isEqual(event.keyCode, KEY_UP)) {
        _this[scrollWindowUp]();
      }
      if (_lodash2.default.isEqual(event.keyCode, KEY_DOWN)) {
        _this[scrollWindowDown]();
      }
    };

    _this[onWindowResized] = function () {
      _this.forceUpdate();
    };

    _this[addNextComponent] = function (componentsToRenderOnMountLength) {
      let componentsToRenderLength = 0;

      if (!_lodash2.default.isNil(componentsToRenderOnMountLength)) {
        componentsToRenderLength = componentsToRenderOnMountLength;
      }

      componentsToRenderLength = Math.max(componentsToRenderLength, _this.state.componentsToRenderLength);

      if (componentsToRenderLength <= _this.state.componentIndex + 1) {
        if (!_lodash2.default.isNil(_this.props.children[_this.state.componentIndex + 1])) {
          componentsToRenderLength++;
        }
      }

      _this.setState({
        componentsToRenderLength,
      });
    };

    _this[setRenderComponents] = function () {
      const newComponentsToRender = [];

      const _loop = function _loop(i) {
        if (!_lodash2.default.isNil(_this.props.children[i])) {
          newComponentsToRender.push(_react2.default.createElement(
            'div',
            {
              key: i,
              ref: function ref(c) {
                return _this[`container_${i}`] = c;
              },
              style: { height: '100%', width: '100%' },
            },
            _this.props.children[i],
          ));
        } else {
          return 'break';
        }
      };

      for (let i = 0; i < _this.state.componentsToRenderLength; i++) {
        const _ret = _loop(i);

        if (_ret === 'break') break;
      }

      return newComponentsToRender;
    };

    _this[scrollWindowUp] = function () {
      if (!_this[scrolling] && !_this.props.blockScrollUp) {
        if (!_lodash2.default.isNil(_this[`container_${_this.state.componentIndex - 1}`])) {
          _this[disableScroll]();
          _this[scrolling] = true;
          _this._pageContainer.style.transform = `translate3d(0, ${(_this.state.componentIndex - 1) * -100}%, 0)`;

          if (_this.props.pageOnChange) {
            _this.props.pageOnChange(_this.state.componentIndex);
          }

          setTimeout(() => {
            _this[_isMounted] && _this.setState((prevState) => ({ componentIndex: prevState.componentIndex - 1 }), () => {
              _this[scrolling] = false;
              _this[previousTouchMove] = null;
            });
          }, _this.props.animationTimer + ANIMATION_TIMER);
        } else {
          _this[enableScroll]();
          if (_this.props.scrollUnavailable) {
            _this.props.scrollUnavailable();
          }
        }
      }
    };

    _this[scrollWindowDown] = function () {
      if (!_this[scrolling] && !_this.props.blockScrollDown) {
        if (!_lodash2.default.isNil(_this[`container_${_this.state.componentIndex + 1}`])) {
          _this[disableScroll]();
          _this[scrolling] = true;
          _this._pageContainer.style.transform = `translate3d(0, ${(_this.state.componentIndex + 1) * -100}%, 0)`;

          if (_this.props.pageOnChange) {
            _this.props.pageOnChange(_this.state.componentIndex + 2);
          }

          setTimeout(() => {
            _this[_isMounted] && _this.setState((prevState) => ({ componentIndex: prevState.componentIndex + 1 }), () => {
              _this[scrolling] = false;
              _this[previousTouchMove] = null;
              _this[addNextComponent]();
            });
          }, _this.props.animationTimer + ANIMATION_TIMER);
        } else {
          _this[enableScroll]();
          if (_this.props.scrollUnavailable) {
            _this.props.scrollUnavailable();
          }
        }
      }
    };

    _this.state = { componentIndex: 0, componentsToRenderLength: 0 };
    _this[previousTouchMove] = null;
    _this[scrolling] = false;
    _this[_isMounted] = false;
    _this[_isBodyScrollEnabled] = true;
    return _this;
  }

  ReactPageScroller.prototype.render = function render() {
    const _this2 = this;

    const _props = this.props;
    const { animationTimer } = _props;
    const { transitionTimingFunction } = _props;
    const { containerHeight } = _props;
    const { containerWidth } = _props;

    return _react2.default.createElement(
      'div',
      {
        style: {
          height: containerHeight, width: containerWidth, overflow: 'hidden', position: 'relative',
        },
        ref: function ref(c) {
          return _this2._pageContainer2 = c;
        },
      },
      _react2.default.createElement(
        'div',
        {
          ref: function ref(c) {
            return _this2._pageContainer = c;
          },
          style: {
            height: '100%',
            width: '100%',
            transition: `transform ${animationTimer}ms ${transitionTimingFunction}`,
          },
          tabIndex: 0,
        },
        this[setRenderComponents](),
      ),
      _react2.default.createElement('div', {}, _props.pagination),
    );
  };

  return ReactPageScroller;
}(_react2.default.Component)), _class.defaultProps = {
  animationTimer: 1000,
  transitionTimingFunction: 'ease-in-out',
  containerHeight: '100vh',
  containerWidth: '100vw',
  blockScrollUp: false,
  blockScrollDown: false,
}, _temp);
exports.default = ReactPageScroller;
ReactPageScroller.propTypes = process.env.NODE_ENV !== 'production' ? {
  animationTimer: _propTypes2.default.number,
  transitionTimingFunction: _propTypes2.default.string,
  pageOnChange: _propTypes2.default.func,
  scrollUnavailable: _propTypes2.default.func,
  containerHeight: _propTypes2.default.oneOfType([_propTypes2.default.number, _propTypes2.default.string]),
  containerWidth: _propTypes2.default.oneOfType([_propTypes2.default.number, _propTypes2.default.string]),
  blockScrollUp: _propTypes2.default.bool,
  blockScrollDown: _propTypes2.default.bool,
} : {};
module.exports = exports.default;
