/* eslint-disable import/prefer-default-export */
/* eslint-disable no-return-await */
export async function postData({ url = '', body }) {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      'api-key': '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', // change api key
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(body),
  });
  return await response.json();
}
