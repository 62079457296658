// import PropTypes from 'prop-types';
import React from 'react';
import PropTypes from 'prop-types';

import ScrollAnimation from 'react-animate-on-scroll';
import styles from './subscription-section.module.scss';
import Container from '../../../layouts/container/container';
import VerticalLines from '../../../components/vertical-lines/ vertical-lines';
import SubscriptionForm from '../../../components/subscription-form/subscription-form';


const SubscriptionSection = ({ data }) => (
  <ScrollAnimation animateOnce initiallyVisible animateIn={styles.animateIn}>
    <section className={`${styles.subscriptionSection} ${styles.sections}`}>
      <VerticalLines />
      <Container>
        <div className={styles.animatedTextContainer}>
          <div className={styles.animatedText}>
            <h3 className={styles.h3}>{data.title}</h3>
          </div>
          <div className={styles.animatedText}>
            <h4 className={`${styles.h4} ${styles.h4_title}`}>{data.subTitle}</h4>
          </div>
        </div>
        <div className={styles.h4_divider} />
        <SubscriptionForm
          placeholder={data.placeholder}
          responseMessage={{
            empty: data.empty,
            exist: data.exist,
            success: data.success,
          }}
          button={data.button}
        />
      </Container>
    </section>
  </ScrollAnimation>
);

SubscriptionSection.propTypes = {
  data: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default SubscriptionSection;
