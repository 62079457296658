import PropTypes from 'prop-types';
import React, { useState, useEffect, useMemo } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import styles from './reports-section.module.scss';
import Container from '../../../layouts/container/container';
import VerticalLines from '../../../components/vertical-lines/ vertical-lines';
import CountBox from '../../../components/count-box/count-box';
import LinkBlock from '../../../components/link/link';
import { replaceSpace } from '../../../helpers/helpers';
import LineIndicator from '../../../components/line-indicator/line-indicator';


const ReportsSection = ({
  activeSection, data, isMobile, sectionName, Pagination,
}) => {
  const [lineHeight, setLineHeight] = useState(0);

  const handleWindowSizeChange = () => {
    const pagination = document.querySelector(`.${styles.animateIn} > div`);
    const topPosition = pagination
      ? `calc(${window.getComputedStyle(pagination).top} + 60px)` : 0;
    setLineHeight(topPosition);
  };
  useEffect(() => {
    if (isMobile) {
      handleWindowSizeChange();
      window.addEventListener('resize', handleWindowSizeChange);
      window.addEventListener('scroll', handleWindowSizeChange);
    }
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
      window.removeEventListener('scroll', handleWindowSizeChange);
    };
  }, [isMobile]);
  useEffect(() => {
    if (lineHeight !== 0) {
      window.removeEventListener('scroll', handleWindowSizeChange);
    }
  }, [lineHeight]);
  const renderMemo = useMemo(() => (
    <>
      <div className={styles.reportsSectionOverlay} style={{ backgroundImage: `url(${data.image.url})` }} />
      <VerticalLines />
      <Container>
        <div className={`${styles.animatedTextContainer} ${styles.animatedTextContainerBox}`}>
          <div className={styles.animatedText}>
            <h3 className={styles.h3}>{data.title}</h3>
          </div>
          <div className={styles.animatedText}>
            <h4 className={styles.h4}>{data.subTitle}</h4>
          </div>
          {/* <div className={styles.animatedText}>
          <h4 className={styles.h4}><FormattedMessage id="homePage.section_4.heading2.l2" /></h4>
        </div> */}

          <div className={styles.countBlock}>
            <div className={styles.animatedTextContainer}>
              <CountBox
                number={data.report1Number}
                parentStyles={styles}
                textArray={[data.report1Text]}
              />
              <div className={`${styles.animatedText} ${styles.linkWrapper}`}>
                <LinkBlock
              // stys={{ marginTop: '66px' }}
                  clsName={styles.linkBlock}
                  isLinkToOtherPage
                  path="/investor-relations/#3"
                  name={data.report1Link}
                  withArrow
                />
              </div>
            </div>
            <div className={styles.animatedTextContainer}>
              <CountBox
                number={data.report2Number}
                parentStyles={styles}
                textArray={[data.report2Text]}
              />
              <div className={`${styles.animatedText} ${styles.linkWrapper}`}>
                <LinkBlock
              // stys={{ marginTop: '66px' }}
                  clsName={styles.linkBlock}
                  isLinkToOtherPage={false}
                  path={data.report2File.url}
                  name={data.report2Link}
                  withArrow
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  ), []);
  const renderSection = () => (
    <section id={replaceSpace(sectionName)} data-active={`active_${activeSection}`} className={`${styles.reportsSection} ${styles.sections}`}>
      {renderMemo}
    </section>
  );
  return (
    isMobile
      ? (
        <ScrollAnimation className={`${styles.reportsSection}`} animateOnce initiallyVisible animateIn={styles.animateIn}>
          {Pagination}
          <LineIndicator
            height={lineHeight}
          />
          {renderSection()}
        </ScrollAnimation>
      ) : renderSection()
  );
};

ReportsSection.propTypes = {
  activeSection: PropTypes.number.isRequired,
  data: PropTypes.objectOf(PropTypes.string).isRequired,
  isMobile: PropTypes.bool.isRequired,
  sectionName: PropTypes.string.isRequired,
  Pagination: PropTypes.node.isRequired,
};

export default ReportsSection;
