// Core
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

// Instruments
import styles from './main-sections.module.scss';
import Container from '../../../layouts/container/container';
import VerticalLines from '../../../components/vertical-lines/ vertical-lines';
import LinkBlock from '../../../components/link/link';

const MainSection = ({
  activeSection,
  data,
  isMobile,
}) => {
  const [sectionLoaded, setSectionLoaded] = useState(false);
  const [windowSize, setWindowSize] = useState(1024);

  const handleWindowSizeChange = () => {
    const width = window.innerWidth || document.documentElement.clientWidth
  || document.body.clientWidth;
    setWindowSize(width);
  };

  useEffect(() => {
    handleWindowSizeChange(); // Set width
    window.addEventListener('resize', handleWindowSizeChange);
    setSectionLoaded(true);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const {
    title, titleW1, titleW2, titleW3, titleW4, image,
  } = data;

  const renderMobileHeaders = () => (
    <>
      <div className={styles.animatedText}>
        <div className={styles.mainSectionHWrapper}>
          <h1 className={styles.h1}>{title}</h1>
        </div>
      </div>
      <div className={styles.animatedText}>
        <div className={styles.mainSectionHWrapper}>
          <h1 className={styles.h1}>
            <div>
              {titleW1}
            </div>
            <div>
              {titleW2}
            </div>
            <div>
              {titleW3}
            </div>
            <div>
              {titleW4}
            </div>
          </h1>
        </div>
      </div>
    </>
  );

  const renderDesktopHeaders = () => (
    <div className={styles.animatedText}>
      <div className={styles.mainSectionHWrapper}>
        <h1 className={styles.h1}>{title}</h1>
        <h1 className={styles.h1}>
          <div>
            {titleW1}
          </div>
          <div>
            {titleW2}
          </div>
          <div>
            {titleW3}
          </div>
          <div>
            {titleW4}
          </div>
        </h1>
      </div>
    </div>
  );
  const renderSection = () => (
    <section id="main-section" data-active={`active_${sectionLoaded ? activeSection : 0}`} className={`${styles.mainSection} ${styles.sections}`}>
      <div className={styles.mainSectionOverlay} style={{ backgroundImage: `url(${image.url})` }} />
      <VerticalLines startAnimation={sectionLoaded} />
      <Container>
        <div className={styles.animatedTextContainer}>
          {
        windowSize < 568
          ? renderMobileHeaders()
          : renderDesktopHeaders()
        }
          <div className={styles.animatedText}>
            <LinkBlock
              path="/investor-relations"
              name={data.link}
              withArrow
            />
          </div>
        </div>
      </Container>
    </section>
  );
  return (
    isMobile
      ? (
        <ScrollAnimation animateOnce initiallyVisible animateIn={styles.animateIn}>
          {renderSection()}
        </ScrollAnimation>
      ) : renderSection()
  );
};

MainSection.propTypes = {
  activeSection: PropTypes.number.isRequired,
  data: PropTypes.objectOf(PropTypes.string).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default MainSection;
