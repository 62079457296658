import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import styles from './pagination.module.scss';

const numbers = ['٠١', '٠٢', '٠٣', '٠٤', '٠٥', '٠٦', '٠٧', '٠٨', '٠٩', '١٠', '١١', '١٢', '١٥'];

const Pagination = ({
  menuItems, changeHandler, activeItem, canScrollUp,
}) => {
  const [isAr, setIsAr] = useState(false);
  useEffect(() => {
    setIsAr(window.location.pathname.indexOf('/ar') !== -1);
  }, []);
  return (
    <div
      data-pagination="pagination"
      className={`${styles.pagination} ${styles.animatedTextContainer} ${canScrollUp ? styles.hide : ''}`}
    >
      {menuItems.map((item, index) => (
        <div className={styles.animatedText}>
          <button
            type="button"
            onClick={() => {
              changeHandler({
                index: index + 1,
                name: item,
              });
            }}
            className={`${styles.pagination_item} ${(activeItem === index + 2 || activeItem === item) ? styles.pagination_item__active : ''}`}
            data-activeitem={(activeItem === index + 2 || activeItem === item) ? 'active' : ''}
            data-path={item}
          >
            {isAr ? `${numbers[index]} ${item}` : `0${index + 1} ${item}`}
          </button>
        </div>
      ))}
    </div>
  );
};

Pagination.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  changeHandler: PropTypes.func.isRequired,
  activeItem: PropTypes.number.isRequired,
  canScrollUp: PropTypes.bool.isRequired,
};

export default Pagination;
