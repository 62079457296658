// import PropTypes from 'prop-types';
// Core
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Instruments
import styles from './subscription-form.module.scss';
import Arrow from '../../assets/svg/arrow.svg';
import { postData } from '../../helpers/senders';

const SubscriptionForm = ({ placeholder, responseMessage, button }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const resetError = () => {
    setError(null);
  };
  const submitForm = async (e) => {
    e.preventDefault();
    const data = {
      url: 'https://api.sendinblue.com/v3/contacts',
      body: {
        updateEnabled: false,
        email,
      },
    };
    const res = await postData(data);
    if (res.code === 'missing_parameter') {
      setError(responseMessage.empty);
    } else if (res.code === 'duplicate_parameter') {
      setError(responseMessage.exist);
    } else if (res.message) {
      setError(res.message);
    } else {
      setEmail('');
      setSuccess(responseMessage.success);
      setTimeout(() => setSuccess(null), 3000);
    }
  };

  return (
    <div className={styles.subscriptionForm}>
      <form className={styles.subscriptionForm_form}>
        <input
          className={styles.subscriptionForm_input}
          type="email"
          value={email}
          placeholder={placeholder}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          onFocus={resetError}
        />
        <div className={styles.subscriptionForm_button__block}>
          <button
            aria-label="Submit"
            type="submit"
            onClick={submitForm}
            className={styles.subscriptionForm_button}
          >
            {button}
          </button>
          <Arrow />
        </div>

      </form>
      {error && <div className={styles.error}>{error}</div>}
      {success && <div className={styles.success}>{success}</div>}
    </div>
  );
};

SubscriptionForm.propTypes = {
  button: PropTypes.string.isRequired,
  responseMessage: PropTypes.objectOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string.isRequired,
};


export default SubscriptionForm;
