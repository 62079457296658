// Core
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Instruments
import { Link } from 'gatsby';
import Arrow from '../../assets/svg/arrow.svg';
import styles from './link.module.scss';

const LinkBlock = ({
  name, path, withArrow, stys, isLinkToOtherPage, clsName, location,
}) => {
  const [isEnLng, setEnLng] = useState(false);
  useEffect(() => {
    setEnLng(window.location.pathname.indexOf('/ar') === -1);
  }, []);
  return (
    <div style={{ ...stys }} className={`${styles.linkBlock} ${clsName}`}>
      {isLinkToOtherPage ? (
        <Link
          to={`${isEnLng ? '' : 'ar/'}${path}`}
          className={styles.link}
          state={location}
        >
          {name}
        </Link>
      ) : <a aria-label="link" href={path} className={styles.link}>{name}</a>}
      {withArrow && <Arrow />}
    </div>
  );
};

LinkBlock.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  withArrow: PropTypes.bool.isRequired,
  stys: PropTypes.objectOf(PropTypes.any),
  isLinkToOtherPage: PropTypes.bool,
  clsName: PropTypes.string,
  location: PropTypes.objectOf(PropTypes.any),
};
LinkBlock.defaultProps = {
  stys: {},
  isLinkToOtherPage: true,
  clsName: '',
  location: null,
};

export default LinkBlock;
