/* eslint-disable react/destructuring-assignment */
// Core
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import ReactPageScroller from '../../lib/index';

// Instruments
import '../../static/styles/index.scss';
import Layout from '../../layouts/en';
import SEO from '../../components/seo';
import MainSection from '../../pages-sections/home-sections/main-section/main-section';
import AboutSection from '../../pages-sections/home-sections/about-section/about-section';
import Pagination from '../../components/pagination/pagination';
import MessageSection from '../../pages-sections/home-sections/message-section/message-section';
import ReportsSection from '../../pages-sections/home-sections/reports-section/reports-section';
import NumbersSection from '../../pages-sections/home-sections/numbers-section/numbers-section';
// import NewsSection from '../../pages-sections/home-sections/news-section/news-section';
import LineIndicator from '../../components/line-indicator/line-indicator';
import SubscriptionSection from '../../pages-sections/home-sections/subscription-section/subscription-section';
import {
  returnNum,
  getPageData,
  setRtl,
  unsetRtl,
} from '../../helpers/helpers';
import useGoToPage from '../../hooks/useGoToPage';

const IndexPage = ({ location, data }) => {
  const locationLink = location.hash.length ? returnNum(location.hash) : 1;
  const [currentPage, setCurrentPage] = useState(locationLink);
  const [lineHeight, setLineHeight] = useState(0);
  const [canScrollUp, setCanScrollUp] = useState(false);
  const [windowSize, setWindowSize] = useState(1024);
  const [showSections, setShowSections] = useState(false);

  const isMobile = windowSize <= 1024;
  const wpData = data && getPageData(data, 'home-page-ar');
  useEffect(() => {
    setRtl(location.pathname);
    return () => {
      unsetRtl();
    };
  }, []);
  const MENU_ITEMS = [
    wpData.section_title_1,
    wpData.section_title_2,
    wpData.section_title_3,
    wpData.section_title_4,
    // wpData.section_title_5, // 'Latest News' add with section
  ];
  const [ref, goToPage] = useGoToPage(location, isMobile, MENU_ITEMS, currentPage);
  const onChangeSection = ({ index, name }) => {
    goToPage(index, name);
  };

  const handleWindowSizeChange = () => {
    const width = window.innerWidth || document.documentElement.clientWidth
  || document.body.clientWidth;
    setWindowSize(width);
  };

  const handleWindowScroll = () => {
    const scrollBlocker = window.pageYOffset > 0 || window.pageYOffset < 0;

    setCanScrollUp(scrollBlocker);
    return true;
  };

  useEffect(() => {
    handleWindowSizeChange(); // Set width
    window.addEventListener('resize', handleWindowSizeChange);
    if (!isMobile) {
      document.addEventListener('scroll', handleWindowScroll, { passive: false });
      setShowSections(false);
    } else {
      setShowSections(true);
    }
    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, [isMobile]);

  useEffect(() => {
    const activePaginationButton = document.querySelector('button[data-activeitem="active"]');
    const defaultHeight = 486;
    if (currentPage === 1) {
      setLineHeight(defaultHeight);
    } else {
      const topPosition = activePaginationButton
        ? activePaginationButton.getBoundingClientRect().top + 20 : defaultHeight;
      setLineHeight(topPosition);
    }
    setTimeout(() => {
      if (showSections !== (currentPage === MENU_ITEMS.length + 1)) {
        setShowSections(currentPage === MENU_ITEMS.length + 1);
      }
    }, 1000); // time for render sections that are out of pageScroller
  }, [currentPage, windowSize]);

  useEffect(() => {
    if (location && location.state && location.state.goToMain) {
      onChangeSection({
        index: 0,
        name: 'main-section',
      });
    }
  }, [location]);

  if (windowSize === 0) {
    return (<></>);
  }

  const renderSections = () => (
    [<MainSection
      activeSection={currentPage}
      isMobile={isMobile}
      data={
        {
          title: wpData.section_1_title,
          titleW1: wpData.section_1_title_w1,
          titleW2: wpData.section_1_title_w2,
          titleW3: wpData.section_1_title_w3,
          titleW4: wpData.section_1_title_w4,
          link: wpData.section_1_link,
          image: wpData.section_1_background_image,
          scroll: wpData.section_1_scroll,
        }
      }
    />,
      <AboutSection
        isMobile={isMobile}
        Pagination={(
          <Pagination
            menuItems={MENU_ITEMS}
            changeHandler={onChangeSection}
            activeItem={wpData.section_title_1}
          />
        )}
        sectionName={wpData.section_title_1}
        data={
        {
          title: wpData.section_2_title,
          titleBold: wpData.section_2_title_bold,
          link: wpData.section_2_link,
          image: wpData.section_2_background_image,
        }
      }
        activeSection={currentPage}
      />,
      <MessageSection
        isMobile={isMobile}
        sectionName={wpData.section_title_2}
        Pagination={(
          <Pagination
            menuItems={MENU_ITEMS}
            changeHandler={onChangeSection}
            activeItem={wpData.section_title_2}
          />
        )}
        data={
      {
        title: wpData.section_3_title,
        subTitle: wpData.section_3_sub_title,
        text1: wpData.section_3_text_paragraph_1,
        text2: wpData.section_3_text_paragraph_2,
        link: wpData.section_3_link,
        image: wpData.section_3_background_image,
      }
    }
        activeSection={currentPage}
      />,
      <ReportsSection
        isMobile={isMobile}
        activeSection={currentPage}
        location={location}
        sectionName={wpData.section_title_3}
        Pagination={(
          <Pagination
            menuItems={MENU_ITEMS}
            changeHandler={onChangeSection}
            activeItem={wpData.section_title_3}
          />
        )}
        data={
        {
          title: wpData.section_4_title,
          subTitle: wpData.section_4_sub_title,
          report1Link: wpData.section_4_report_block_1_link,
          report1Number: wpData.section_4_report_block_1_number,
          report1Text: wpData.section_4_report_block_1_text,
          report1File: wpData.section_report_block_1_file,
          report2Link: wpData.section_4_report_block_2_link,
          report2Number: wpData.section_4_report_block_2_number,
          report2Text: wpData.section_4_report_block_2_text,
          report2File: wpData.section_report_block_2_file,
          image: wpData.section_4_background_image,
        }
      }
      />,
      <NumbersSection
        isMobile={isMobile}
        activeSection={currentPage}
        sectionName={wpData.section_title_4}
        Pagination={(
          <Pagination
            menuItems={MENU_ITEMS}
            changeHandler={onChangeSection}
            activeItem={wpData.section_title_4}
          />
        )}
        data={
        {
          title: wpData.section_5_title,
          subTitle: wpData.section_5_sub_title,

          report1Number: wpData.section_5_report_block_1_number,
          report1Text1: wpData.section_5_report_block_1_text_1,
          report1Text2: wpData.section_5_report_block_1_text_2,

          report2Number: wpData.section_5_report_block_2_number,
          report2Text1: wpData.section_5_report_block_2_text_1,
          report2Text2: wpData.section_5_report_block_2_text_2,
          report2Text3: wpData.section_5_report_block_2_text_3,

          report3Number: wpData.section_5_report_block_3_number,
          report3Text1: wpData.section_5_report_block_3_text_1,
          report3Text2: wpData.section_5_report_block_3_text_2,

          image: wpData.section_5_background_image,
        }
      }
      />,
      // <NewsSection
      //   isMobile={isMobile}
      //   activeSection={currentPage}
      //   sectionName={wpData.section_title_5}
      //   Pagination={(
      //     <Pagination
      //       menuItems={MENU_ITEMS}
      //       changeHandler={onChangeSection}
      //       activeItem={wpData.section_title_5}
      //     />
      //   )}
      //   data={
      //     {
      //       title: wpData.section_6_title,
      //       subTitle: wpData.section_6_sub_title,
      //       image: wpData.section_6_background_image,
      //       posts: data.allWordpressPost.edges,
      //     }
      //   }
      // />,
    ]
  );
  return (
    <>
      <Layout
        currentSection={currentPage}
        sectionsCount={MENU_ITEMS.length + 1}
        location={location}
        isMobile={isMobile}
        showFooter={showSections}
        footerData={
          {
            footerLink1: wpData.footer_link_1,
            footerLink2: wpData.footer_link_2,
            footerLink3: wpData.footer_link_3,
            footerLink4: wpData.footer_link_4,
            footerLink5: wpData.footer_link_5,
            footerLink6: wpData.footer_link_6,
            footerLink7: wpData.footer_link_7,
            footerEmailLabel: wpData.footer_email_label,
            footerEmail: wpData.footer_email,
            footerPhoneLabel: wpData.footer_phone_label,
            footerPhone: wpData.footer_phone,
            copyright: wpData.copyright,
          }
        }
      >
        <SEO title="Home" />
        <div data-currentPage={`currentPage_${currentPage}`}>
          {isMobile ? (
            <>
              <div ref={ref}>
                {renderSections().map((section) => section)}
              </div>
            </>
          ) : (
            <ReactPageScroller
              ref={ref}
              pageOnChange={(num) => setCurrentPage(num)}
              customPageNumber={currentPage}
              transitionTimingFunction="cubic-bezier(.435,.01,.135,1)"
              blockScrollUp={canScrollUp}
              scrollBlocker={showSections}
              pagination={(
                <Pagination
                  canScrollUp={canScrollUp}
                  menuItems={MENU_ITEMS}
                  changeHandler={onChangeSection}
                  activeItem={currentPage}
                />
            )}
            >
              {renderSections().map((section) => section)}
            </ReactPageScroller>
          )}
          {!isMobile && (
          <LineIndicator
            height={lineHeight}
            activeItem={currentPage}
          />
          )}
        </div>
        { (currentPage === MENU_ITEMS.length + 1 || isMobile)
          && (
          <SubscriptionSection
            activeSection={currentPage}
            data={
            {
              title: wpData.subscription_title,
              subTitle: wpData.subscription_sub_title,
              placeholder: wpData.subscription_placeholder,
              button: wpData.subscription_button,
              empty: wpData.email_empty,
              exist: wpData.email_exist,
              success: wpData.success,
            }
          }
          />
          )}
      </Layout>
    </>
  );
};

IndexPage.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any),
};
IndexPage.defaultProps = {
  data: null,
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    allWordpressPost(limit: 3, filter: {categories: {eq: 14}}) {
      edges {
        node {
          title
          id
          template
          content
          slug
          date(locale: "")
          acf {
            post_text
            post_title
          }
        }
      }
    }
    allWordpressPage {
      edges {
        node {
          acf {

            section_title_1
            section_title_2
            section_title_3
            section_title_4
            section_title_5

            section_1_link
            section_1_scroll
            section_1_title
            section_1_title_w4
            section_1_title_w3
            section_1_title_w2
            section_1_title_w1
            section_1_background_image {
              alt
              url
            }
            section_2_link
            section_2_title
            section_2_title_bold
            section_2_background_image {
              alt
              url
            }
            section_3_sub_title
            section_3_text_paragraph_1
            section_3_text_paragraph_2
            section_3_title
            section_3_link
            section_3_background_image {
              alt
              url
            }
            section_4_report_block_1_link
            section_4_report_block_1_number
            section_4_report_block_2_link
            section_4_report_block_1_text
            section_4_report_block_2_number
            section_4_report_block_2_text
            section_report_block_1_file {
              alt
              url
            }
            section_report_block_2_file {
              alt
              url
            }
            section_4_sub_title
            section_4_title
            section_4_background_image {
              alt
              url
            }
            section_5_report_block_1_number
            section_5_report_block_1_text_1
            section_5_report_block_1_text_2
            section_5_report_block_2_number
            section_5_report_block_2_text_1
            section_5_report_block_2_text_3
            section_5_report_block_2_text_2
            section_5_report_block_3_number
            section_5_report_block_3_text_1
            section_5_report_block_3_text_2
            section_5_sub_title
            section_5_title
            section_5_background_image {
              alt
              url
            }
            section_6_title
            section_6_sub_title
            section_6_background_image {
              alt
              url
            }

            subscription_title
            subscription_sub_title
            subscription_placeholder
            subscription_button
            email_empty
            email_exist
            success
          
            footer_link_1
            footer_link_2
            footer_link_3
            footer_link_4
            footer_link_5
            footer_link_6
            footer_link_7
            footer_email_label
            footer_email
            footer_phone_label
            footer_phone
            copyright
          }
          title
          slug
        }
      }
    }
  }`;
